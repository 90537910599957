bcl.s.panorama = {
  props: {
    dataAttr: "image-360",
    pannellumConfig: {},
  },

  init: function (baseDom) {
    const baseFind = baseDom || document,
      elements = baseFind.querySelectorAll("[data-" + bcl.s.panorama.props.dataAttr + "]");

    if (elements.length > 0) {
      // Load assets and process elements
      bcl.s.panorama
        .loadAssets()
        .then(() => {
          elements.forEach((element) => {
            bcl.s.panorama.process(element);
          });
        })
        .catch((error) => {
          console.error("Error loading Pannellum:", error);
        });
    }
  },

  loadAssets: function () {
    return new Promise((resolve, reject) => {
      // Load Pannellum CSS
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = "https://cdn.jsdelivr.net/npm/pannellum@2/build/pannellum.min.css";
      document.head.appendChild(link);

      // Load Pannellum script
      const script = document.createElement("script");
      script.src = "https://cdn.jsdelivr.net/npm/pannellum@2/build/pannellum.min.js";
      script.onload = () => {
        resolve();
      };
      script.onerror = () => {
        reject(new Error("Error loading Pannellum"));
      };
      document.body.appendChild(script);
    });
  },

  process: function ($element) {
    $element.onload = function () {
      const image = this.src;
      if (image) {
        const height = this.height;
        const wrapper = document.createElement("div");
        bcl.u.addClass(wrapper, "panorama-wrapper");
        wrapper.style.height = height + "px";
        bcl.u.wrap($element, wrapper);
        this.remove();

        window.pannellum.viewer(wrapper, {
          type: "equirectangular",
          autoLoad: true,
          panorama: image,
        });
      }
    };
  },
};

bcl.u.docReady(bcl.s.panorama.init);
