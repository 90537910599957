import tingle from "tingle";

bcl.modalMeetingRoom = {
  props: {
    customFunc: null,
    cssSelectorModal: "c-modal-meeting-room",
    cssSelectorInjectContent: "c-modal-inject",
    cssSelector: "carouselMeetingRoom-JS",
    cssSelectorSwiper: ".swiper-wrapper",
    carouselCreated: false,
    config: {
      // <---- Config for carousel
      slidesPerView: 1,
      grabCursor: true,
      watchOverflow: true,
      observer: false,
      observeParents: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
        clickable: true,
      },
    },
    modalType: ["src", "inject"],
    hasPriceButton: false,
    containerContent: ".c-meeting__modal-content",
    lang: "",
    buttonCloseCloned: "",
    modalDefaultProperties: {
      closeMethods: ["overlay", "button", "escape"],
      closeLabel: "< Go back",
      onOpen: function () {
        const close = bcl.modalMeetingRoom.props.$modal.modal.querySelector(".tingle-modal__close");

        if (close) {
          bcl.modalMeetingRoom.props.$modal.modalBoxContent.appendChild(close);
        }

        document.dispatchEvent(new CustomEvent("modal-open", { detail: this.modalBox }));
        if (bcl.modalMeetingRoom?.props?.$modal?.modalBoxContent && bcl.c?.headerDetail?.props) {
          const readMore = bcl.modalMeetingRoom.props.$modal.modalBoxContent.querySelector(bcl.c.headerDetail.props.readMore);
          const readLess = bcl.modalMeetingRoom.props.$modal.modalBoxContent.querySelector(bcl.c.headerDetail.props.readLess);
          if (readMore && readLess) {
            const completeText = bcl.modalMeetingRoom.props.$modal.modalBoxContent.querySelector(bcl.c.headerDetail.props.completeText);
            if (readMore.getAttribute("listener") !== "true") {
              readMore.addEventListener("click", () => {
                readMore.setAttribute("listener", "true");
                bcl.modalMeetingRoom.loadMore(readLess, completeText, readMore);
              });
            }
            if (readLess.getAttribute("listener") !== "true") {
              readLess.addEventListener("click", () => {
                readLess.setAttribute("listener", "true");
                bcl.modalMeetingRoom.loadLess(readLess, completeText, readMore);
              });
            }
          }
        }
        bcl.u.computeScript(this.modalBoxContent);
      },
      onClose: function () {
        document.dispatchEvent(new CustomEvent("modal-close", { detail: this.modalBox }));
      },
    },
    $modal: null,
    list: {},
  },
  loadMore: function (nodeLess, nodeCompleteText, nodeMore) {
    bcl.u.addClass(nodeMore, bcl.c.headerDetail.props.hidden);
    bcl.u.removeClass(nodeLess, bcl.c.headerDetail.props.hidden);
    bcl.u.removeClass(nodeCompleteText, bcl.c.headerDetail.props.hidden);
  },

  loadLess: function (nodeLess, nodeCompleteText, nodeMore) {
    bcl.u.addClass(nodeLess, bcl.c.headerDetail.props.hidden);
    bcl.u.addClass(nodeCompleteText, bcl.c.headerDetail.props.hidden);
    bcl.u.removeClass(nodeMore, bcl.c.headerDetail.props.hidden);
  },
  preInit: function () {
    bcl.u.docReady(bcl.modalMeetingRoom.init);
  },

  init: function (baseDom) {
    const baseFind = baseDom ? baseDom : document,
      modals = baseFind.querySelectorAll("button." + bcl.modalMeetingRoom.props.cssSelectorModal);
    let author = false;

    if (baseFind.querySelector("html")) {
      bcl.modalMeetingRoom.props.lang = baseFind.querySelector("html").getAttribute("lang");
    }
    if (bcl.u.containsClass(document.body, "isAuthor")) {
      author = true;
    }

    for (let i = 0; i < modals.length; i++) {
      const $button = modals[i];

      $button.addEventListener("click", bcl.modalMeetingRoom.initModal);

      if (author) {
        $button.setAttribute("data-href", $button.dataset.href + "?wcmmode=disabled");
      }
    }
    const $body = baseFind.querySelector("body");
    if (!author && $body && $body.dataset.genericAlert && !bcl.cookie.get(bcl.modalMeetingRoom.props.lang + "genericAlert") && !$body.dataset.noShowGenericAlert) {
      const href = $body.dataset.genericAlert;

      window.addEventListener("preInitLoaded", function () {
        setTimeout(function () {
          bcl.modalMeetingRoom.initModal(null, href);
        }, 2000);
      });
    }
  },

  initModal: function (event, href) {
    if (event) {
      event.preventDefault();
    }

    let modalType;
    if (this.dataset) {
      modalType = this.dataset.modaltype;
      bcl.modalMeetingRoom.props.hasPriceButton = this.dataset.haspricebutton === "true" ? true : false;
    }

    if (modalType) {
      switch (modalType) {
        case bcl.modalMeetingRoom.props.modalType[0]:
          bcl.modalMeetingRoom.initInject(this);
          break;
        case bcl.modalMeetingRoom.props.modalType[1]:
          bcl.modalMeetingRoom.initSibling(this);
          break;
      }
    } else {
      const url = href ? href : this.dataset.href;

      if (url) {
        bcl.modalMeetingRoom.initInject(this, href);
      }
    }
  },

  initSibling: function ($el) {
    const url = $el.dataset.href,
      modal = bcl.modalMeetingRoom.props.list[url],
      $modal = modal && modal.modalBoxContent.querySelector("." + bcl.modalMeetingRoom.props.cssSelectorModal);

    if (modal && $modal && !$modal.dataset.modalrigth) {
      bcl.modalMeetingRoom.props.$modal = modal;
      modal.open();
    } else {
      const injectContent = $el.parentNode.getElementsByClassName(bcl.modalMeetingRoom.props.cssSelectorInjectContent);

      if (injectContent && injectContent.length > 0) {
        const injectContentCloned = injectContent[0].cloneNode(true);
        bcl.modalMeetingRoom.openModal(injectContentCloned, null, $el);
      }
    }
  },

  initInject: function ($el, href) {
    let url = href ? href : $el.dataset.href,
      author;
    const modal = bcl.modalMeetingRoom.props.list[url],
      $modal = modal && modal.modalBoxContent.querySelector("." + bcl.modalMeetingRoom.props.cssSelectorModal);

    if (bcl.u.containsClass(document.body, "isAuthor")) {
      author = true;
    }
    if (author) {
      const urlAuthor = $el.dataset.urlAuthor;
      url = urlAuthor;
      url = url.replace("?wcmmode=disabled", "");
    } else {
      const urlPublish = $el.dataset.urlPublish;
      url = urlPublish;
    }

    if (modal && $modal && !$modal.dataset.modalrigth) {
      bcl.modalMeetingRoom.props.$modal = modal;
      modal.open();
    } else {
      const $param = href ? href : $el;
      if (
        bcl.c.headerDetail.props.eventRoomIndexObject.eventRoomList[bcl.c.headerDetail.props.eventRoomIndexObject.currentEventRoomIndex] &&
        bcl.c.headerDetail.props.eventRoomIndexObject.eventRoomList[bcl.c.headerDetail.props.eventRoomIndexObject.currentEventRoomIndex].htmlCached != ""
      ) {
        const roomEventContentCached = { responseText: bcl.c.headerDetail.props.eventRoomIndexObject.eventRoomList[bcl.c.headerDetail.props.eventRoomIndexObject.currentEventRoomIndex].htmlCached };
        bcl.modalMeetingRoom.openModal(roomEventContentCached.responseText, null, $el);
      } else {
        bcl.ajax.getRequest(null, url, bcl.modalMeetingRoom.initInjectCallback, $param);
      }
    }
  },

  initInjectCallback: function (response, $el) {
    bcl.modalMeetingRoom.openModal(response.responseText, null, $el);
  },
  initInjectCallbackByRow: function (response) {
    bcl.modalMeetingRoom.props.$modal.setContent(response.responseText);
    bcl.modalMeetingRoom.initMeetingRoomCarousel();

    //set currentIndex when modal opened
    setTimeout(function () {
      bcl.modalMeetingRoom.searchCurrentIndexOnTableByTitle();
    }, 1000);

    bcl.modalMeetingRoom.initArrowsMeetingRoomLeftRightArrows();
    bcl.modalMeetingRoom.saveContentOnCache(response.responseText);
    bcl.modalMeetingRoom.addClonedCloseButtonOnModal();
    bcl.c.headerDetail.initNodeReadMore();
  },

  openModal: function (content, properties, $el) {
    if (!properties) {
      bcl.modalMeetingRoom.props.modalDefaultProperties.onClose = function () {
        document.dispatchEvent(new CustomEvent("modal-close", { detail: this.modalBox }));
        bcl.cookie.set(bcl.modalMeetingRoom.props.lang + "genericAlert", "true", { path: "/" });
        this.modalBox?.closest(".tingle-modal")?.remove();
      };
      properties = bcl.modalMeetingRoom.props.modalDefaultProperties;
    }

    const titleAssetObject = document.createElement("div");
    typeof content === "object" ? titleAssetObject.appendChild(content) : (titleAssetObject.innerHTML = content);

    const titleAsset = titleAssetObject.querySelector("[data-close]");
    if (titleAsset) {
      const closeLabel = titleAsset.dataset.close;
      if (closeLabel) {
        properties.closeLabel = closeLabel;
      }
    }

    if ($el.dataset.beforeinject) {
      const callback = $el.dataset.beforeinject;
      content = bcl.u.executeFunctionByName(callback, window, $el, content);
    }

    bcl.modalMeetingRoom.props.$modal = new tingle.modal(properties);
    bcl.modalMeetingRoom.props.$modal.setContent(content);

    const $modal = bcl.modalMeetingRoom.props.$modal.modalBoxContent.querySelector("." + bcl.modalMeetingRoom.props.cssSelectorModal);

    if ($modal) {
      const $parentModal = bcl.modalMeetingRoom.props.$modal.modal,
        classMobile = $modal.dataset.modalrigth;

      bcl.u.removeClass($parentModal, classMobile);

      if (bcl.u.mobile.isMobile()) {
        bcl.u.addClass($parentModal, classMobile);
      }

      if ($modal.dataset.addclass) {
        const classList = $modal.dataset.addclass.split(".");

        for (let i = 0; i < classList.length; i++) {
          bcl.u.addClass(bcl.modalMeetingRoom.props.$modal.modalBoxContent, classList[i]);
        }

        $modal.dataset.addclass = "";
      }
    }

    if (typeof $el === "object") {
      bcl.modalMeetingRoom.props.list[$el.dataset.href] = bcl.modalMeetingRoom.props.$modal;
    }

    bcl.modalMeetingRoom.props.$modal.modalCloseBtnIcon.innerHTML =
      // eslint-disable-next-line @stylistic/js/max-len
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path fill="#384044" fill-rule="nonzero" d="M45.1385 42.6451L27.2089 24.7156 45.3571 6.5673c.321-.3209.5185-.7617.5185-1.2484 0-.9734-.79-1.7633-1.7634-1.7633-.4867 0-.9275.1974-1.2484.5184L24.7156 22.2222 6.5673 4.074c-.3209-.321-.7617-.5184-1.2484-.5184-.9734 0-1.7633.79-1.7633 1.7633 0 .4867.1974.9275.5184 1.2449l18.1482 18.1518L4.074 42.8638c-.321.321-.5184.7617-.5184 1.2484 0 .9734.79 1.7634 1.7633 1.7634.4867 0 .9275-.1975 1.2484-.5185L24.7156 27.209 42.645 45.1385l.0423-.0423c.3174.4584.8253.7794 1.4248.7794.9734 0 1.7634-.79 1.7634-1.7634 0-.603-.321-1.1074-.7794-1.4248l.0423-.0423z"/></svg>';

    //initCarrousel on modal if exists ( for event rooms on hotel page )
    bcl.modalMeetingRoom.initMeetingRoomCarousel();

    //set currentIndex when modal opened
    bcl.modalMeetingRoom.searchCurrentIndexOnTableByTitle();

    //save cache event rooms on variable
    bcl.modalMeetingRoom.saveContentOnCache(content);

    //init arrow buttons
    bcl.modalMeetingRoom.initArrowsMeetingRoomLeftRightArrows();

    bcl.modalMeetingRoom.cloneCloseButtonOnModal();

    //init read more
    bcl.c.headerDetail.initNodeReadMore();
  },

  searchCurrentIndexOnTableByTitle: function () {
    const titleCell = bcl.modalMeetingRoom.props.$modal.modalBoxContent.querySelector(".roomNameText-JS")?.textContent;
    if (!titleCell) {
      return;
    }

    //get all name cells
    const allNamesTablesCell = document.querySelectorAll(".c-meeting__table_name_room-JS");
    if (allNamesTablesCell) {
      bcl.u.forEach(allNamesTablesCell, function ($item) {
        const title = $item.dataset.roomName;
        if (title === titleCell) {
          const currentPosition = $item.dataset.eventroomposition;
          //set current index
          if (currentPosition) {
            bcl.c.headerDetail.props.eventRoomIndexObject.currentEventRoomIndex = parseInt(currentPosition);
          }
        }
      });
    }
  },
  cloneCloseButtonOnModal: function () {
    const buttonXCornerModal = document.querySelector(".tingle-modal__close");
    bcl.c.headerDetail.props.buttonCloseCloned = buttonXCornerModal.cloneNode(true);
  },
  addClonedCloseButtonOnModal: function () {
    const meetingRoomModalContainer = document.querySelector(".tingle-modal-box__content-parent-JS");
    meetingRoomModalContainer.after(bcl.c.headerDetail.props.buttonCloseCloned);
    bcl.c.headerDetail.props.buttonCloseCloned.addEventListener("click", function () {
      bcl.modalMeetingRoom.close();
    });
  },

  saveContentOnCache: function (content) {
    const $eventRoomDetailModal = document.getElementById("eventRoomDetailModal");
    if ($eventRoomDetailModal != undefined) {
      const currentEventRoomPosition = bcl.c.headerDetail.props.eventRoomIndexObject.currentEventRoomIndex;
      bcl.c.headerDetail.props.eventRoomIndexObject.eventRoomList[currentEventRoomPosition].htmlCached = content;
    }
  },

  launchModal: function () {
    setTimeout(function () {
      bcl.modalMeetingRoom.props.$modal.open();
      bcl.modalMeetingRoom.searchCurrentIndexOnTableByTitle();
    }, 1000);
  },

  initMeetingRoomCarousel: function () {
    bcl.modalMeetingRoom.launchModal();
    bcl.u.swiper.init(bcl?.modalMeetingRoom?.props?.$modal?.modal, bcl.modalMeetingRoom.props);
  },

  initArrowsMeetingRoomLeftRightArrows: function () {
    setTimeout(function () {
      const modalButtonRight = document.querySelector(bcl.c.headerDetail.props.eventRoomModalButtonRight);
      const modalButtonLeft = document.querySelector(bcl.c.headerDetail.props.eventRoomModalButtonLeft);

      const arrowRightRoomModalJS = document.querySelector(bcl.c.headerDetail.props.arrowRightRoomModalJS);
      const arrowLeftRoomModalJS = document.querySelector(bcl.c.headerDetail.props.arrowLeftRoomModalJS);

      //disable right row at start
      if (bcl.c.headerDetail.props.eventRoomIndexObject.currentEventRoomIndex + 1 == bcl.c.headerDetail.props.eventRoomIndexObject.eventRoomList.length) {
        bcl.c.headerDetail.props.arrowRightRoomModalEnabledJS = false;
        bcl.c.headerDetail.props.arrowLeftRoomModalEnabledJS = true;
        setTimeout(function () {
          const $arrowRightRoomModalJS = document.querySelector(bcl.c.headerDetail.props.arrowRightRoomModalJS);
          bcl.u.addClass($arrowRightRoomModalJS, bcl.c.headerDetail.props.modDisabled);
        }, 300);
      }

      if (bcl.c.headerDetail.props.eventRoomIndexObject.currentEventRoomIndex + 1 == bcl.c.headerDetail.props.eventRoomIndexObject.eventRoomList.length - 1) {
        bcl.c.headerDetail.props.arrowRightRoomModalEnabledJS = true;
        bcl.c.headerDetail.props.arrowLeftRoomModalEnabledJS = true;
      } else if (bcl.c.headerDetail.props.eventRoomIndexObject.currentEventRoomIndex == 0) {
        bcl.c.headerDetail.props.arrowRightRoomModalEnabledJS = true;
        bcl.c.headerDetail.props.arrowLeftRoomModalEnabledJS = false;

        setTimeout(function () {
          const $arrowLeftRoomModalJS = document.querySelector(bcl.c.headerDetail.props.arrowLeftRoomModalJS);
          bcl.u.addClass($arrowLeftRoomModalJS, bcl.c.headerDetail.props.modDisabled);
        }, 500);
      }

      if (modalButtonRight && modalButtonLeft) {
        modalButtonRight.addEventListener("click", function () {
          if (bcl.c.headerDetail.props.arrowRightRoomModalEnabledJS == true && bcl.c.headerDetail.props.eventRoomIndexObject.currentEventRoomIndex < bcl.c.headerDetail.props.eventRoomIndexObject.eventRoomList.length) {
            bcl.u.removeClass(arrowRightRoomModalJS, bcl.c.headerDetail.props.modDisabled);
            bcl.u.removeClass(arrowLeftRoomModalJS, bcl.c.headerDetail.props.modDisabled);

            bcl.c.headerDetail.props.arrowRightRoomModalEnabledJS = true;
            bcl.c.headerDetail.props.arrowLeftRoomModalEnabledJS = true;

            if (bcl.c.headerDetail.props.eventRoomIndexObject.currentEventRoomIndex + 1 == bcl.c.headerDetail.props.eventRoomIndexObject.eventRoomList.length) {
              bcl.c.headerDetail.props.arrowRightRoomModalEnabledJS = false;
              setTimeout(function () {
                const $arrowRightRoomModalJS = document.querySelector(bcl.c.headerDetail.props.arrowRightRoomModalJS);
                bcl.u.addClass($arrowRightRoomModalJS, bcl.c.headerDetail.props.modDisabled);
              }, 300);
            }

            bcl.c.headerDetail.props.eventRoomIndexObject.currentEventRoomIndex = bcl.c.headerDetail.props.eventRoomIndexObject.currentEventRoomIndex + 1;
            const nextRoomIndex = parseInt(bcl.c.headerDetail.props.eventRoomIndexObject.currentEventRoomIndex);

            if (bcl.c.headerDetail.props.eventRoomIndexObject.eventRoomList[nextRoomIndex]) {
              let nextPath = bcl.c.headerDetail.props.eventRoomIndexObject.eventRoomList[nextRoomIndex].path;

              if (bcl.u.containsClass(document.body, "isAuthor")) {
                const urlAuthor = bcl.c.headerDetail.props.eventRoomIndexObject.eventRoomList[nextRoomIndex].pathAuthor;
                nextPath = urlAuthor;
                nextPath = nextPath.replace("?wcmmode=disabled", "");
              } else {
                const urlPublish = bcl.c.headerDetail.props.eventRoomIndexObject.eventRoomList[nextRoomIndex].pathPublish;
                nextPath = urlPublish;
              }

              if (
                bcl.c.headerDetail.props.eventRoomIndexObject.eventRoomList[bcl.c.headerDetail.props.eventRoomIndexObject.currentEventRoomIndex] &&
                bcl.c.headerDetail.props.eventRoomIndexObject.eventRoomList[bcl.c.headerDetail.props.eventRoomIndexObject.currentEventRoomIndex].htmlCached != ""
              ) {
                bcl.modalMeetingRoom.initInjectCallbackByRow({ responseText: bcl.c.headerDetail.props.eventRoomIndexObject.eventRoomList[bcl.c.headerDetail.props.eventRoomIndexObject.currentEventRoomIndex].htmlCached });
              } else {
                bcl.ajax.getRequest(null, nextPath, bcl.modalMeetingRoom.initInjectCallbackByRow);
              }
            }
          }
        });

        modalButtonLeft.addEventListener("click", function () {
          if (bcl.c.headerDetail.props.arrowLeftRoomModalEnabledJS == true && bcl.c.headerDetail.props.eventRoomIndexObject.currentEventRoomIndex > 0) {
            bcl.c.headerDetail.props.arrowRightRoomModalEnabledJS = true;

            bcl.u.removeClass(arrowLeftRoomModalJS, bcl.c.headerDetail.props.modDisabled);
            bcl.u.removeClass(arrowRightRoomModalJS, bcl.c.headerDetail.props.modDisabled);

            bcl.c.headerDetail.props.eventRoomIndexObject.currentEventRoomIndex = bcl.c.headerDetail.props.eventRoomIndexObject.currentEventRoomIndex - 1;
            const nextRoomIndex = parseInt(bcl.c.headerDetail.props.eventRoomIndexObject.currentEventRoomIndex);
            let nextPath = bcl.c.headerDetail.props.eventRoomIndexObject.eventRoomList[nextRoomIndex].path;

            if (bcl.u.containsClass(document.body, "isAuthor")) {
              const urlAuthor = bcl.c.headerDetail.props.eventRoomIndexObject.eventRoomList[nextRoomIndex].pathAuthor;
              nextPath = urlAuthor;
              nextPath = nextPath.replace("?wcmmode=disabled", "");
            } else {
              const urlPublish = bcl.c.headerDetail.props.eventRoomIndexObject.eventRoomList[nextRoomIndex].pathPublish;
              nextPath = urlPublish;
            }

            if (
              bcl.c.headerDetail.props.eventRoomIndexObject.eventRoomList[bcl.c.headerDetail.props.eventRoomIndexObject.currentEventRoomIndex] &&
              bcl.c.headerDetail.props.eventRoomIndexObject.eventRoomList[bcl.c.headerDetail.props.eventRoomIndexObject.currentEventRoomIndex].htmlCached != ""
            ) {
              bcl.modalMeetingRoom.initInjectCallbackByRow({ responseText: bcl.c.headerDetail.props.eventRoomIndexObject.eventRoomList[bcl.c.headerDetail.props.eventRoomIndexObject.currentEventRoomIndex].htmlCached });
            } else {
              bcl.ajax.getRequest(null, nextPath, bcl.modalMeetingRoom.initInjectCallbackByRow);
            }

            //limit left
            if (bcl.c.headerDetail.props.eventRoomIndexObject.currentEventRoomIndex == 0) {
              bcl.c.headerDetail.props.arrowRightRoomModalEnabledJS = true;
              bcl.c.headerDetail.props.arrowLeftRoomModalEnabledJS = false;

              setTimeout(function () {
                const $arrowLeftRoomModalJS = document.querySelector(bcl.c.headerDetail.props.arrowLeftRoomModalJS);
                bcl.u.addClass($arrowLeftRoomModalJS, bcl.c.headerDetail.props.modDisabled);
              }, 500);
            }
          }
        });
      }
    }, 500);
  },

  getUrlParam: function ($url, $param, $mode) {
    let paramValue = "";
    if ($mode == "author") {
      const url = new URL(window.location.protocol + "//" + window.location.host + $url);
      paramValue = url.searchParams.get($param);
    } else if ($mode == "publish") {
      const url = new URL(window.location.host + $url);
      paramValue = url.searchParams.get($param);
    }
    return paramValue;
  },

  computePriceButton: function ($el) {
    if ($el && $el.closest("article")) {
      const priceContainer = $el.closest("article").querySelector(".card-hotel-price-JS");
      const clone = priceContainer.cloneNode(true);
      bcl.modalMeetingRoom.props.$modal.modal.querySelector(".booking-price-modal-JS").after(clone);
      const $button = bcl.modalMeetingRoom.props.$modal.modal.querySelector(".booking-button-modal-JS");
      $button.addEventListener("click", bcl.modalMeetingRoom.closeAndTriggerBooking);
    }
  },

  close: function () {
    if (!bcl.modalMeetingRoom.props.$modal) {
      return;
    }
    bcl.modalMeetingRoom.props.$modal.setContent("");
    bcl.modalMeetingRoom.props.$modal.close();
  },
  closeModalMeetingRooms: function () {
    bcl.modalMeetingRoom.props.$modal.setContent("");
  },
};

bcl.preInit.push(bcl.modalMeetingRoom.preInit);
