const swiperMobile = {
  props: {
    mobileFlag: null,
    components: ["swiper-item-JS", "card", "launcher", "aem-GridColumn", "c-hero-menu--item", "c-card-hotel"],
    selectorName: "[data-swiper]",
    classNameChild: ".aem-Grid",
    classContainer: "swiper",
    classWrapper: "swiper-wrapper",
    classPagination: "swiper-pagination",
    classNavNext: "swiper-button-next",
    classNavPrev: "swiper-button-prev",
    classSlide: "swiper-slide",
    classHidden: "mod--hidden",
    classType: "swiper-type-",
    nameAttrType: "data-swiper",
    valueAttrTypeMobile: "OnlyMobile",
    nameAttrNode: "data-swiper-node",
  },

  dataType: function (type) {
    if (type == "slider") {
      return {
        spaceBetween: 10,
        grabCursor: true,
        watchOverflow: true,
        observer: true,
        observeParents: true,
        slidesPerView: "auto",
        freeMode: true,
      };
    }

    if (type == "carousel") {
      return {
        spaceBetween: 12,
        grabCursor: true,
        watchOverflow: true,
        observer: true,
        observeParents: true,
        slidesPerView: "auto",
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      };
    }

    if (type == "carouselCustom") {
      return {
        grabCursor: true,
        watchOverflow: true,
        observer: true,
        observeParents: true,
        slidesPerView: "auto",
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      };
    }

    if (type == "carouselCustomSimple") {
      return {
        grabCursor: true,
        watchOverflow: true,
        observer: true,
        observeParents: true,
        slidesPerView: "auto",
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      };
    }

    return {};
  },

  preInit: function () {
    bcl.c?.cardHotelList?.init?.();
  },

  init: function () {
    if (bcl.editMode) {
      return;
    }

    this.preInit();

    this._resize(this.props);
    bcl.u.resize(this.resize.bind(this));
  },

  resize: function () {
    if (bcl.editMode) {
      return;
    }

    this._resize(this.props);
  },

  _resize: function (properties) {
    const mobileFlagTemp = bcl.u.mobile.isMobile();

    if (mobileFlagTemp != properties.mobileFlag) {
      if (mobileFlagTemp) {
        this.execute(properties, true);
      } else {
        this.executeDesktop(properties);
      }

      properties.mobileFlag = mobileFlagTemp;
    }
  },

  execute: function (properties, comparateValue) {
    const components = document.querySelectorAll(properties.selectorName);

    bcl.u.forEach(components, ($component) => {
      if (this.getSwiperMobile($component, properties) === comparateValue) {
        this.generateSwiperInComponent($component, properties);
      }
    });
  },

  getSwiperMobile: function ($component, properties) {
    const type = $component.getAttribute(properties.nameAttrType);

    if (type) {
      return type.match(properties.valueAttrTypeMobile) ? true : false;
    }

    return false;
  },

  generateSwiperInComponent: function ($component, properties) {
    const $grid = this.getNodeGrid($component, properties);

    if ($grid) {
      const type = this.getTypeSwiper($component, properties),
        $swiper = this.createContainerAdditional(type, properties);
      let $lastElement;

      if (!properties.callbackEvaluateChildren) {
        const data = {
          $component: $component,
          $grid: $grid,
          $containerSwiper: $swiper.children[0],
        };

        if ($component.dataset.multiplechildren) {
          for (const element of $grid.children) {
            data.$grid = element;
            $lastElement = this.searchElementsInGrid(data, properties);
          }
        } else {
          $lastElement = this.searchElementsInGrid(data, properties);
        }
      }

      if ($lastElement) {
        this.insertAndInitSlider(
          {
            $grid: $grid,
            $swiper: $swiper,
            $lastElement: $lastElement,
            type: type,
            $component: $component,
          },
          properties,
        );
      } else {
        $swiper.remove();
      }
    }
  },

  getTypeSwiper: function ($component, properties) {
    const type = $component.getAttribute(properties.nameAttrType);

    if (type) {
      return type.replace(properties.valueAttrTypeMobile, "");
    }

    return "carousel";
  },

  getNodeGrid: function ($component, properties) {
    if ($component.getAttribute(properties.nameAttrNode) == "true") {
      return $component;
    }

    return $component.querySelector(properties.classNameChild);
  },

  searchElementsInGrid: function (data, properties) {
    let $lastElement = null;
    const components = properties.components,
      children = [];

    for (const element of data.$grid.children) {
      children.push(element);
    }

    if (!children.length) {
      return $lastElement;
    }

    for (const element of components) {
      for (const element2 of children) {
        const $child = children[element2];

        if (bcl.u.containsClass($child, element)) {
          this.changeByMobileComponent(
            {
              $element: $child,
              $containerSwiper: data.$containerSwiper,
              className: $child.className,
            },
            properties,
          );

          $lastElement = $child;
        }
      }

      if ($lastElement) {
        break;
      }
    }

    return $lastElement;
  },

  insertAndInitSlider: function (data, properties) {
    const $nextElement = data.$lastElement && data.$lastElement.nextSibling;

    if ($nextElement) {
      data.$grid.insertBefore(data.$swiper, $nextElement);
    } else {
      data.$grid.appendChild(data.$swiper);
    }

    const configSwiper = this.dataType(data.type, properties);

    configSwiper && this.createContainerAdditionalByConfig(configSwiper, properties, data);

    setTimeout(() => {
      configSwiper.init = false;

      const mySwiper = new window.Swiper(data.$swiper, configSwiper);

      mySwiper.on("init", () => {
        const $items = data.$swiper.querySelectorAll(".swiper-slide-duplicate .cmp-image .cmp-image__image--is-loading");

        if ($items.length) {
          bcl.u.forEach($items, ($item) => {
            bcl.u.resetImage($item.parentNode);
          });
        }
      });
      mySwiper.init();

      // TODO
      data.$component.style.padding = 0;

      bcl.s.dataAnchor.init(data.$swiper);
    }, 0);
  },

  createContainerAdditional: function (type, properties) {
    const $container = this.createDiv(properties.classContainer);

    if (type != "carouselCustom") {
      $container.style.width = "100%";
    }

    $container.style.height = "100%";

    this.createDiv(properties.classWrapper, $container);

    return $container;
  },

  createContainerAdditionalByConfig: function (configSwiper, properties, data) {
    bcl.u.addClass(data.$swiper, properties.classType + data.type);

    if (configSwiper.pagination) {
      this.createDiv(properties.classPagination, data.$swiper);
    }

    if (configSwiper.navigation) {
      if (configSwiper.navigation.nextEl) {
        this.createDiv(properties.classNavNext, data.$swiper);
      }
      if (configSwiper.navigation.prevEl) {
        this.createDiv(properties.classNavPrev, data.$swiper);
      }
    }
  },

  createDiv: function (className, $parent, type) {
    if (!type) {
      type = "div";
    }

    const $element = document.createElement(type);

    if (className) {
      bcl.u.addClass($element, className);
    }

    if ($parent) {
      $parent.appendChild($element);
    }

    return $element;
  },

  changeByMobileComponent: function (data, properties) {
    const $item = data.$element,
      $copy = document.createElement("div");

    $copy.dataset.idref = "copynode";

    $item.parentNode.insertBefore($copy, $item);

    bcl.u.copyAllClass($item, $copy);
    bcl.u.addClass($copy, "hidden");

    bcl.u.removeAllClass($item);
    bcl.u.addClass($item, properties.classSlide);
    bcl.u.addClass($item, data.className);

    data.$containerSwiper.appendChild($item);
  },

  changeByDesktopComponent: function ($grid, $item) {
    const $copy = $grid.querySelector('div[data-idref="copynode"]');

    if (!$copy) {
      $item.parentNode.removeChild($item);
      return;
    }

    bcl.u.removeClass($copy, "hidden");

    bcl.u.copyAllClass($copy, $item);
    $grid.insertBefore($item, $copy);

    $grid.removeChild($copy);
  },

  executeDesktop: function (properties) {
    const components = document.querySelectorAll(properties.selectorName);

    bcl.u.forEach(components, ($component) => {
      const $grid = this.getNodeGrid($component, properties);

      if ($grid && this.getSwiperMobile($component, properties)) {
        const $container = $grid.querySelector("." + properties.classWrapper);

        if (!$container) {
          return;
        }

        if ($component.dataset.multiplechildren) {
          this.multipleChildrenDesktop($container, $grid, properties);
        } else {
          this.clearChildrenDesktop($container, $grid, properties);
        }

        $grid.style = null;

        this.deleteContainerAdditional($grid, properties);
      }
    });
  },

  multipleChildrenDesktop: function ($container, $grid, properties) {
    for (let e = 0; e < $grid.children.length - 1; e++) {
      this.clearChildrenDesktop($container, $grid.children[e], properties, $container.children.length - $grid.children[e].children.length);
    }
  },

  clearChildrenDesktop: function ($container, $grid, properties, index) {
    index = index || 0;

    while ($container.children.length > 0 && $container.children.length > index && !bcl.u.isDescendant($grid, $container)) {
      $container.children[0].style = null;
      this.changeByDesktopComponent($grid, $container.children[0], properties);
    }
  },

  deleteContainerAdditional: function ($parent, properties) {
    const $container = $parent.querySelector("." + properties.classContainer);

    if ($container && $parent === $container.parentNode) {
      $parent.removeChild($container);
    }
  },
};

export default swiperMobile;
